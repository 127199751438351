import { IRegisterRequest, Texercise } from "../services/types/auth.types";
import { SignalData } from "simple-peer";

export type TLocationState = {
  pathname: string;
  state: { pathname: string };
};
export interface IUser {
  [key: string]: any;
  account: string;
  address: string;
  avatar: string;
  birth: Date;
  email: string;
  firstName: string;
  fullName: string;
  gender: number;
  id: string;
  guid: string;
  lastName: string;
  passport: string;
  password: string; //remove when server is ready
  phone: string;
  planId: number;
  roleId: number;
  statusId: number;
  therapists: ITherapist[];
  mockAppointments: IAppointmentExample[];
  appointments?: IAppointment[];
  messages: IMessage[];
  updates: IUpdate[];
  settings: ISettings;
  prescriptions: IPrescription[];
  invoices: IInvoice[];
  videos: IVideo[];
  card?: string;
  unreadMessages: number;
  myDayText?: "";
  myTreatmentText?: "";
  country?: string | null;
  exercises: Texercise[];
}

export enum Category {
  Day = "day",
  Treatment = "treatment",
  Help = "help",
  Goals = "goals",
  Profile = "profile",
  Messages = "messages",
  Contacts = "contacts",
  Prevencher = "prevencher",
  Terms = "terms",
}
export enum SubCategories {
  Account = "account",
  Card = "card",
  Invoices = "invoices",
  Settings = "settings",
  Subscription = "subscription",
  Support = "support",
  Notifications = "notifications",
  Faq = "faq",
  Coupon = "coupon",
  Terms = "terms",
  Treatment = "treatment",
  SupportGroup = "supportGroup",
  Practice = "practice",
  Docs = "docs",
  Calendar = "calendar",
  Preferences = "preferences",
  Prescriptions = "prescriptions",
  Achieved = "achieved",
  Power = "power",
  Progress = "progress",
  Reports = "reports",
}
export enum ProfileCategories {
  Account = "account",
  Card = "card",
  Invoices = "invoices",
  Settings = "settings",
}
export enum SettingCategories {
  Subscription = "subscription",
  Support = "support",
  Notifications = "notifications",
  Faq = "faq",
  Coupon = "coupon",
  Terms = "terms",
}

export interface ISubCategory {
  value: string;
  title: string;
}

export interface IPrevencherCategory extends ISubCategory {
  img: string;
}
export interface ITherapist {
  id: string;
  name: string;
  status: string;
  avatar: string;
  online: boolean;
  phone: string;
  video: string;
  about: string;
  tag?: string;
  messages?: IMessage[];
  fields: string[];
  color: string;
}
export interface IAppointment {
  appointments: {
    appointmentDate: string;
    appointmentReport?: string;
    comments?: string;
    companyId: number;
    endTime?: string;
    id: number;
    mediaId: number;
    medias: { id: number; name: string };
    startTime: string;
    therapistsId: number;
    usersId: number;
  };
  therapistName: string;
}

export interface IAppointmentExample {
  id: number;
  therapist: ITherapist;
  date: Date;
}

export interface IMessage {
  messageId: string;
  id: string;
  date: Date;
  message: string;
  unread: boolean;
  userId?: string;
}

export interface IUpdate {
  id: number;
  companyId: number;
  statusId?: number;
  title: string;
  desc: string;
  date: Date;
  message: string;
  unread: boolean;
  userId?: string;
  generalRRMSEQ?: string;
}

export type TInsertAnswerRequest = {
  Id: string;
  QuestionnaireId: string;
  QuestionsId: string;
  UsersId: string;
  Answer: string;
};

export type TMessageWithNextQuestionId = IMessage & {
  nextQuestionId: number;
};
export type RegisterRequestKeys = keyof IRegisterRequest;
export type TPrevencherChatForGuestsOption = {
  id: number;
  desc: string;
  link?: string;
  input?: boolean;
  next?: "signup";
  value?: string;
  textArea?: boolean;
};
export type TPrevencherForGuestsQuestion = IMessage & {
  userId: string;
  nextQuestionId: number;
  dataType: "options" | "input";
  options: TPrevencherChatForGuestsOption[];
} & (
    | { dataType: "options"; inputType?: never; inputId?: never } // If dataType is "options", inputType and inputId are not allowed
    | {
        dataType: "input";
        inputType: "text" | "number" | "date";
        inputId: RegisterRequestKeys;
      }
  ); // If dataType is "input", inputType and inputId are required

export interface TQuestionnaireCompany {
  CompanyId: number;
  Dayly?: number;
  Id: number;
  Monthly?: number;
  QuestionnaireCompaniesHeadersId: number;
  QuestionnaireCompaniesTypeId: number;
  StatusId: number;
  TherapistQuestionnaireId?: number;
  UserQuestionnaireId: number;
}

export interface IAppointmentDay {
  date: string;
  appointments: any;
}

export interface ISettings {
  sendToEmail: boolean;
  sendAds: boolean;
  sendNotifications: boolean;
}

export interface IPrescription {
  id: string;
  medicine: string;
  doze: string;
  instructions: string;
  date: Date;
  therapist: ITherapist;
  exp: Date;
}

export interface IInvoice {
  id: string;
  date: Date;
  sum: string;
}

export interface IVideo {
  link: string;
  title: string;
  about: string;
}

export interface IPopupWithoutProps {
  onClose: () => void;
}

export interface IScannedDoc {
  name: string;
  date: Date;
  status: string;
}

export interface INotification {
  unread: boolean;
  text: string;
  section: string;
}
export interface IForummessage {
  id: string;
  user: {
    userName: string;
    avatar: string;
    tag?: string;
    date: Date;
  };
  message: string;
  replyTo?: string;
}
export interface IForumThread {
  id: string;
  title: string;
  messages: IForummessage[];
}
export interface IReport {
  id: string;
  title: string;
  subTitle: string;
  date: Date;
  content: string;
}

export interface IHomePageContentsSection {
  value: string;
  icon: string;
  text: string;
  navigateTo: string;
  state: object;
}
export interface IGenderOption {
  id: number;
  name: string;
}

export type TUrgentForm = {
  way: string;
  theme: string;
  urgent: string;
  comment: string;
};
export type TUrgentFormKeys = keyof TUrgentForm;

export type TSendFileForm = {
  category: ISubCategory;
  details: ISubCategory;
  urgent: ISubCategory;
  file: FormData | null;
};

export interface IChatMessage {
  messageId: number;
  senderId: number;
  receiverId: number;
  content: string;
  sentDate: string;
}

export interface IChatAppointment {
  appointmentDate: string;
  appointmentReport?: string;
  comments?: string;
  companyId: number;
  endTime?: string;
  id: number;
  mediaId: number;
  medias: { id: number; name: string };
  startTime: string;
  therapistsId: number;
  usersId: number;
}
export interface IUserChatData {
  userId: number;
  fullName: string;
  avatar?: string;
  online: boolean;
  isTyping: boolean;
  messages: IChatMessage[];
  appointment?: IChatAppointment;
}
export interface ICall {
  isReceivingCall: boolean;
  from: { name: string; avatar?: string | null };
  signal: SignalData;
}
