import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import {
  setWsConnection,
  setConnected,
  addMessage,
  setUserTyping,
  endCall,
  updateChatData,
  setCall,
  setOffer,
} from "../slices/chat.slice";
import { IChatMessage, IUserChatData } from "../../types/types";
import {
  BASE_URL_TEST,
  END_CALL_CODE,
  TYPING_CODE,
} from "../../utils/constants";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }), // Base URL is not used for WebSocket
  endpoints: (builder) => ({
    initializeConnection: builder.query<
      void,
      { userId: string; shouldConnect: boolean }
    >({
      // @ts-ignore
      async queryFn({ userId, shouldConnect }, { dispatch }) {
        if (!shouldConnect)
          return { error: { message: "appointment is no longer valid" } };
        const connection = new HubConnectionBuilder()
          .withUrl(`${BASE_URL_TEST}chat?uid=${userId}`, {
            withCredentials: false,
          })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();
        // Handle reconnection logic
        connection.onreconnecting((error) => {
          console.warn(
            `Connection lost due to error "${error}". Reconnecting...`
          );
          dispatch(setConnected(false));
        });
        connection.onreconnected((connectionId) => {
          console.log(
            "Connection reestablished with connectionId: ",
            connectionId
          );
          dispatch(setConnected(true));
        });
        connection.onclose(async (error) => {
          console.error("Connection closed due to error:", error);
          try {
            await connection.start();
            console.log("Reconnected successfully.");
          } catch (err) {
            console.error("Reconnection failed:", err);
          }
        });
        connection.on("ReceiveChatData", (data: IUserChatData[]) => {
          dispatch(updateChatData(data));
        });
        connection.on(
          "ReceiveChatDataUsers",
          (data: [{ userId: number; online: boolean }]) => {
            dispatch(updateChatData(data));
          }
        );
        connection.on("onMessage", (message: IChatMessage) => {
          console.log(message);
          if (message.content.includes(TYPING_CODE)) {
            dispatch(setUserTyping(message));
          } else if (message.content.includes(END_CALL_CODE)) {
            dispatch(endCall(message));
          } else {
            dispatch(addMessage(message));
          }
        });
        //video
        connection.on(
          "ReceiveOffer",
          (offer: {
            offer: string;
            from: { name: string; avatar?: string };
          }) => {
            dispatch(setCall({ isReceivingCall: true, from: offer.from }));
            dispatch(setOffer(offer.offer));
          }
        );
        // connection.on("ReceiveAnswer", (answer: string) => {
        //   // handleReceiveAnswer(answer);
        // });

        // Retry logic for connection: Retry every 3 seconds up to 5 times
        const attemptReconnection = async () => {
          for (let attempt = 0; attempt < 5; attempt++) {
            if (connection.state === HubConnectionState.Disconnected) {
              try {
                await connection.start();
                dispatch(setWsConnection(connection));
                dispatch(setConnected(true));
                break; // Stop the loop after successful reconnection
              } catch (error) {
                console.error(
                  `Reconnection attempt ${attempt + 1} failed. Retrying...`
                );
                await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
              }
            }
          }
        };

        // Ensure the connection is in 'Disconnected' state before attempting to start
        if (connection.state === HubConnectionState.Disconnected) {
          try {
            await connection.start();
            dispatch(setWsConnection(connection));
            dispatch(setConnected(true));
            return { data: null };
          } catch (error: any) {
            console.error("Connection error during start:", error);
            // Retry connection if it fails initially
            await attemptReconnection();
            return { error: error };
          }
        } else {
          console.warn(
            "Connection is already started or not in 'Disconnected' state."
          );
          return { error: "Connection is not in 'Disconnected' state." };
        }
      },
    }),
  }),
});

export const { useLazyInitializeConnectionQuery } = chatApi;
