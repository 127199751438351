import { useFormik } from "formik";
import signinFormStyles from "./signinForms.module.css";
import { ISigninFormProps } from "../../../types/signinTypes";
import { signUpValidationSchema2 } from "../../../utils/validation";
import { countryOptions, phonePrefixOptions } from "../../../utils/constants";
import React, { useEffect, useState } from "react";
import { useGetAllGendersQuery } from "../../../services/api/utils.api";
import { useUpdateUserMutation } from "../../../services/api/auth.api";

function SigninForm2({ state, setState, changeForm }: ISigninFormProps) {

  const [update, { isError, isLoading }] = useUpdateUserMutation();

  const { data: genderOptions } = useGetAllGendersQuery({ skip: false });

  const [phonePrefix, setPhonePrefix] = useState(phonePrefixOptions[0]);

  const onPhoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPhonePrefix(value);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const formik = useFormik({
    initialValues: {
      birth: formatDate(state.birth),
      phone: state.phone.length === 10 ? state.phone.slice(-7) : state.phone,
      country: state.country,
      genderId: state.genderId,
    },
    validationSchema: signUpValidationSchema2,
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle form submission
      setState({
        ...state,
        birth: values.birth,
        phone: `${phonePrefix}${values.phone}`,
        country: values.country,
        genderId: values.genderId,
      });
      if (state.id != 0){
        update(state)
      }
      changeForm();
    },
  });

  useEffect(() => {
    if(state.phone)
    setPhonePrefix(state.phone.slice(0,3))
    
  },[state])




  return (
    <form
      className={signinFormStyles.signin_private__form}
      onSubmit={formik.handleSubmit}
    >
      <label htmlFor="birth" className={signinFormStyles.signin_private__label}>
        תאריך לידה
      </label>
      <input
        type="date"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.birth && signinFormStyles.signin_private__input_invalid
        }`}
        name="birth"
        id="birth"
        value={formik.values.birth}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="שם פרטי"
        required={true}
        autoFocus={true}
      />
      <label htmlFor="phone" className={signinFormStyles.signin_private__label}>
        טלפון נייד
      </label>
      <div className={signinFormStyles.signin_private__phone_inputs}>
        <input
          type="text"
          className={`${signinFormStyles.signin_private__input} ${
            signinFormStyles.signin_private__input_phone
          } ${
            formik.errors.phone &&
            signinFormStyles.signin_private__input_invalid
          }`}
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="טלפון נייד"
          required={true}
        />
        <select
          value={phonePrefix}
          onChange={onPhoneChange}
          className={`${signinFormStyles.signin_private__input} ${signinFormStyles.signin_private__select}`}
        >
          {phonePrefixOptions.map((prefix, index) => (
            <option key={index} value={prefix}>
              {prefix}
            </option>
          ))}
        </select>
      </div>
      <label
        htmlFor="country"
        className={signinFormStyles.signin_private__label}
      >
        מדינה
      </label>
      <select
        name={"country"}
        value={formik.values.country}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`${signinFormStyles.signin_private__input}`}
      >
        {countryOptions.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
      <label
        htmlFor="gender"
        className={signinFormStyles.signin_private__label}
      >
        מגדר
      </label>
      {genderOptions && (
        <select
          name={"genderId"}
          value={formik.values.genderId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${signinFormStyles.signin_private__input}`}
        >
          {genderOptions.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      )}

      {formik.errors && (
        <p className={`${signinFormStyles.signin_private__invalid_message}`}>
          {formik.errors.birth ?? formik.errors.phone ?? formik.errors.genderId}
        </p>
      )}
      <button
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className={`${signinFormStyles.signin_private__btn} ${
          !formik.isValid || formik.isSubmitting
            ? signinFormStyles.signin_private__btn_disabled
            : "hover"
        }`}
      >
        המשך
      </button>
    </form>
  );
}

export default SigninForm2;
