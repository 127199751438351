import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomePage from "../../pages/home/home";
import Onboarding from "../../pages/onboarding/onboarding";
import LoginPage from "../../pages/login/login";
import LoginSmsCode from "../../pages/login/loginSmsCode";
import SigninPage from "../../pages/signin/signin";
import SigninPrivatePage from "../../pages/signin/signin_private";
import { Category, TLocationState } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import MainContentLayout from "../mainContentLayout/mainContentLayout";
import ProfilePage from "../../pages/profile/profilePage";
import TreatmentPage from "../../pages/treatment/treatmentPage";
import ChatPage from "../../pages/chat/ChatPage";
import ProtectedRoute from "../protectedRoute/ProtectedRoute";
import PrevencherPage from "../../pages/prevencher/prevencherPage";
import { subCategories } from "../../utils/categories";
import ForumPage from "../../pages/forum/forumPage";
import NotFoundPage from "../../pages/notFound/notFoundPage";
import { customerService, mobileWidth } from "../../utils/constants";
import RestorePasswordPage from "../../pages/restore_password/restorePasswordPage";
import Terms from "../terms/terms";
import SearchPage from "../../pages/search/SearchPage";
import GoalsPage from "../../pages/goals/goalsPage";
import PocGuestsChatPage from "../../pages/prevencher/PocGuestsChatPage";
import { useAppSelector } from "../../services/hooks";
import { isAuthSelector } from "../../services/selectors/auth.selectors";
import Modal from "../modals/modal/Modal";
import useInactivityTimer from "../../hooks/useInactivityTimer";
import InactivityWarning from "../modals/inactivityWarning/inactivityWarning";
import {
  useLazyGetAppointmentsByUserIdQuery,
  useGetUserQuery,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import { userSelector } from "../../services/selectors/user.selectors";
import { useLazyInitializeConnectionQuery } from "../../services/api/chat.api";
import { useSelector } from "react-redux";
import { chatStateSelector } from "../../services/selectors/chat.selector";
import VideoCallWindow from "../modals/videoCallWindow/videoCallWindow";
import { useActions } from "../../services/hooks/useActions";
import MediaDevicesErrorPopup from "../modals/mediaDevicesErrorPopup/mediaDevicesErrorPopup";

function App() {
  const location = useLocation() as TLocationState;
  const navigate = useNavigate();
  const mobile = useMediaQuery(mobileWidth);
  const isAuth = useAppSelector(isAuthSelector);
  const user = useAppSelector(userSelector);
  const chatState = useSelector(chatStateSelector);

  const { isLoading: isUserLoading } = useGetUserQuery();
  const [activeCategory, setActiveCategory] = useState(Category.Day);
  const [subCategory, setSubCategory] = useState(subCategories.account);
  const [showWarning, setShowWarning] = useState(false);
  const [showMediaDevicesErrorPopup, setShowMediaDevicesErrorPopup] =
    useState(false);

  const { setMyStream } = useActions();
  // useInactivityTimer(setShowWarning);

  //get user appointments
  const [getAppointmentsByUserId] = useLazyGetAppointmentsByUserIdQuery();
  useEffect(() => {
    if (user.guid) {
      getAppointmentsByUserId({
        companyId: user.companyId,
        guid: user.guid,
      });
    }
  }, [user.guid, getAppointmentsByUserId]);
  //ws
  const [connect] = useLazyInitializeConnectionQuery();
  useEffect(() => {
    if (isAuth && user.id) {
      connect({
        userId: user.id,
        // appointmentId: appointmentId || "",
        // shouldConnect: Boolean(!appointment?.EndTime),
        shouldConnect: true,
      });
    }
  }, [isAuth, user.id, connect]);

  const changeCategory = (e: React.MouseEvent, cat: Category) => {
    if (!mobile) {
      e?.stopPropagation();
    }
    if (location.pathname !== "/home") {
      navigate("/home");
    }
    setActiveCategory(cat);
  };

  //redirect from button click
  useEffect(() => {
    if (location.state?.pathname === "/treatment") {
      setSubCategory(subCategories.settings);
    } else if (
      location.state?.pathname === "techSupport" ||
      location.state?.pathname === customerService ||
      location.state?.pathname === "terms"
    ) {
      setSubCategory(subCategories.settings);
    } else if (location.state?.pathname === "profile") {
      setSubCategory(subCategories.account);
    } else if (location.state?.pathname === "notifications") {
      setSubCategory(subCategories.invoices);
    } else if (location.state?.pathname === "updateCard") {
      setSubCategory(subCategories.card);
    } else if (location.state?.pathname === "myCalendar") {
      setSubCategory(subCategories.calendar);
    }
  }, [location.state?.pathname]);

  useEffect(
    () => {
      if (chatState.chatUsers.length > 0) {
        if (navigator.mediaDevices?.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((currentStream) => {
              setMyStream(currentStream);
            })
            .catch((err) => {
              setShowMediaDevicesErrorPopup(true);
              console.log(2, err);
            });
        } else {
          setShowMediaDevicesErrorPopup(true);
          console.error(
            "getUserMedia is not supported in this browser or environment."
          );
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatState.chatUsers]
  );

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <>
      {isAuth && showWarning && (
        <Modal onClose={() => setShowWarning(false)}>
          <InactivityWarning onClose={() => setShowWarning(false)} />
        </Modal>
      )}
      {isAuth && chatState.call.isReceivingCall && <VideoCallWindow />}
      {isAuth && showMediaDevicesErrorPopup && (
        <Modal onClose={() => setShowMediaDevicesErrorPopup(false)}>
          <MediaDevicesErrorPopup
            onClose={() => setShowMediaDevicesErrorPopup(false)}
          />
        </Modal>
      )}
      <Routes>
        <Route path={"/"} element={<Onboarding />} />
        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/login/enter_code"} element={<LoginSmsCode />} />
        <Route
          path={"/signin"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <SigninPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/signin/private"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <SigninPrivatePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/restore_password"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <RestorePasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/home"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={activeCategory}
                changeCategory={changeCategory}
              >
                <HomePage
                  activeCategory={activeCategory}
                  setCategory={setSubCategory}
                />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/profile"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Profile}
                changeCategory={changeCategory}
              >
                <ProfilePage activeCategory={subCategory} />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/treatment"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Treatment}
                changeCategory={changeCategory}
              >
                <TreatmentPage activeCategory={subCategory} />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/treatment/search"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Treatment}
                changeCategory={changeCategory}
              >
                <SearchPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/goals"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Goals}
                changeCategory={changeCategory}
              >
                <GoalsPage activeCategory={subCategory} />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/prevencher"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Prevencher}
                changeCategory={changeCategory}
              >
                <PrevencherPage
                  activeCategory={Category.Prevencher}
                  changeCategory={changeCategory}
                />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/chat"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <ChatPage
                activeCategory={Category.Messages}
                changeCategory={changeCategory}
              />
            </ProtectedRoute>
          }
        />
        <Route path={"/guests_chat"} element={<PocGuestsChatPage />} />
        <Route
          path={"/terms"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Terms}
                changeCategory={changeCategory}
              >
                <Terms />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/forum"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Help}
                changeCategory={changeCategory}
              >
                <ForumPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/forum/:id"}
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MainContentLayout
                activeCategory={Category.Help}
                changeCategory={changeCategory}
              >
                <ForumPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route path={"*"} element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
