import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { actions as userActions } from "../slices/authUser.slice";
import { actions as therapistsActions } from "../slices/therapists.slice";
import { actions as goalsActions } from "../slices/goals.slice";
import { actions as chatActions } from "../slices/chat.slice";
import { useMemo } from "react";

const rootActions = {
  ...userActions,
  ...goalsActions,
  ...therapistsActions,
  ...chatActions,
};
export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
