import popupStyles from "./appointmentCard.module.css";
import { IAppointmentExample } from "../../../types/types";
import { getDuration } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useLazyGetTherapistDatesQuery } from "../../../services/api/therapists.api";
import Modal from "../modal/Modal";
import CancelAppointmentConfirmationPopup from "../cancelAppointmentConfirmation_popup/cancelAppointmentConfirmation_popup";
import CloseButton from "../../closeButton/closeButton";

interface IAppointmentCardProps {
  onClose: () => void;
  appointmentData: IAppointmentExample;
}
function AppointmentCard({ onClose, appointmentData }: IAppointmentCardProps) {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [fetchDates, { data, isError }] = useLazyGetTherapistDatesQuery();

  return openModal ? (
    <Modal
      onClose={() => {
        setOpenModal(false);
        onClose();
      }}
    >
      <CancelAppointmentConfirmationPopup
        appointmentData={appointmentData}
        onClose={() => {
          setOpenModal(false);
          onClose();
        }}
      />
    </Modal>
  ) : (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>פרטי פגישה</h2>
      <p className={popupStyles.popup__text}>שם המטפל</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.therapist.name}
      </p>
      <p className={popupStyles.popup__text}>תאריך פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {`${appointmentData.date.getDate()}/${
          appointmentData.date.getMonth() + 1
        }/${appointmentData.date.getFullYear()}`}
      </p>
      <p className={popupStyles.popup__text}>שעת פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {getDuration(appointmentData.date, 50)}
      </p>

      <button
        type={"button"}
        className={`${popupStyles.popup__btn} ${popupStyles.popup__nextBtn} hover`}
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        עריכת פרטים
      </button>
      <div
        className={`${popupStyles.popup__optionsBar} ${
          showOptions && popupStyles.popup__optionsBar_visible
        }`}
      >
        <p className={popupStyles.popup__text}>האם תרצה</p>
        <ul className={popupStyles.popup__options}>
          <li>
            <button
              onClick={() => setOpenModal(true)}
              className={`${popupStyles.popup__nextBtn} ${popupStyles.popup__optionBtn} `}
            >
              לבטל פגישה
            </button>
          </li>
          <li>
            <button
              className={`${popupStyles.popup__nextBtn} ${popupStyles.popup__optionBtn}`}
              onClick={() => {
                fetchDates(appointmentData.therapist.id);
                navigate("search", {
                  replace: true,
                  state: { appointmentData },
                });
              }}
            >
              לשנות פגישה
            </button>
          </li>
        </ul>
      </div>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} button hover`}
        onClick={() => {
          navigate("search");
        }}
      >
        קביעת פגישה נוספת
      </button>
    </article>
  );
}

export default AppointmentCard;
