import { IResponse } from "../types";
import {
  ILoginCodeResponse,
  IRegisterRequest,
  IUserAuthResponse,
  TLoginGoogleRequest,
  TLoginRequest,
  TLoginWithCodeRequest,
  TSendCodeRequest,
  TSetPasswordRequest,
} from "../types/auth.types";
import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<IUserAuthResponse, TLoginRequest>({
      query: ({ email, password }) => ({
        url: `user/login`,
        method: "POST",
        body: {
          email: email,
          password: password,
          RoleId: 1
        },
      }),
    }),
    loginGoogle: build.mutation<IUserAuthResponse, TLoginGoogleRequest>({
      query: (body) => ({
        url: `user/login/google`,
        method: "POST",
        body,
      }),
    }),
    loginWithCode: build.mutation<ILoginCodeResponse, TLoginWithCodeRequest>({
      query: ({ phone }) => ({
        url: `user/code`,
        method: "POST",
        body: JSON.stringify(phone),
      }),
    }),
    sendCode: build.mutation<IUserAuthResponse, TSendCodeRequest>({
      query: ({ phone, code }) => ({
        url: `user/auth`,
        method: "POST",
        body: JSON.stringify({ phone: phone, code: code }),
      }),
    }),
    logout: build.mutation<IResponse, string>({
      query: (refreshToken) => ({
        url: `/logout`,
        method: "POST",
        body: {
          token: refreshToken,
        },
      }),
    }),
    register: build.mutation<IUserAuthResponse, IRegisterRequest>({
      query: ({
        usersFlowId,
        firstName,
        lastName,
        phone,
        passport,
        email,
        password,
        birth,
        genderId,
        country,
      }) => ({
        url: `user/signup/${usersFlowId}`,
        method: "POST",
        body: {
          firstName,
          lastName,
          phone,
          passport: passport.toString(),
          email,
          password,
          birth,
          genderId,
          country,
        },
      }),
    }),
    updateUser: build.mutation<IUserAuthResponse, IRegisterRequest>({
      query: ({
        usersFlowId,
        firstName,
        lastName,
        phone,
        passport,
        email,
        birth,
        genderId,
        country,
        id,
        CompanyId,
        roleId,
        guid
      }) => ({
        url: `admintech/Admin_Users_DML/2/${CompanyId}`,
        method: "POST",
        body: {
          firstName,
          lastName,
          phone,
          passport: passport.toString(),
          email,
          birth,
          genderId,
          country,
          id,
          CompanyId,
          roleId,
          guid
        },
      }),
    }),
    setUserPassword: build.query<IUserAuthResponse, TSetPasswordRequest>({

      query: ({ userId, password, roleId }) => ({
        url: `admintech/Admin_SetUserPassword/${userId}/${password}/${roleId}`,
        method: "GET",
      })
    }),
    restorePassword: build.mutation<IResponse, string>({
      query: (emailOrPhone) => ({
        url: `/restore_password`,
        method: "POST",
        body: {
          emailOrPhone,
        },
      }),
    }),
    enterAsGuest: build.query<IUserAuthResponse, number>({
      query: (usersFlowId) => ({
        url: `user/signup/${usersFlowId}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useRestorePasswordMutation,
  useRegisterMutation,
  useUpdateUserMutation,
  useLoginGoogleMutation,
  useEnterAsGuestQuery,
  useSetUserPasswordQuery
} = authApi;
