import loginStyles from "../../../pages/login/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginEmailValidationSchema } from "../../../utils/validation";
import { authApi } from "../../../services/api/auth.api";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { useAppSelector } from "../../../services/hooks";
import { useLazyGetCurrentFlowQuery } from "../../../services/api/user.api";
import { existingUserFlowIdSelector } from "../../../services/selectors/user.selectors";

function LoginEmailForm() {
  const navigate = useNavigate();
  const [login, { isError }] = authApi.useLoginMutation();
  const existingUserFlowId = useAppSelector(existingUserFlowIdSelector);
  const [getCurrentUserFlow] = useLazyGetCurrentFlowQuery();
  const loginError = useAppSelector(errorUserSelector);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const response = await login({ email, password });
    if ("data" in response && response.data.success) {
      navigate("/home");

    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginEmailValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission
      handleLogin(formik.values);
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={loginStyles.login__form}>
      <input
        type="email"
        required={true}
        className={`${loginStyles.login__input} ${
          loginStyles.login__input_email
        } ${formik.errors.email && loginStyles.login__input_invalid}`}
        name="email"
        id="email"
        placeholder="דואר אלקטרוני"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <input
        type="password"
        required={true}
        className={`${loginStyles.login__input} ${
          formik.errors.password && loginStyles.login__input_invalid
        }`}
        name="password"
        id="password"
        placeholder="סיסמה"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.errors && (
        <p className={`${loginStyles.login__invalid_message}`}>
          {formik.errors.email ?? formik.errors.password}
        </p>
      )}
      {isError && (
        <p className={`${loginStyles.login__invalid_message}`}>{loginError}</p>
      )}
      <Link
        to={"/restore_password"}
        className={loginStyles.login__restorePasswordLink}
      >
        שכחת סיסמה?
      </Link>
      <button
        type="submit"
        disabled={
          !formik.values.email || !formik.isValid || formik.isSubmitting
        }
        className={`button ${loginStyles.login__button} ${
          !formik.values.email || !formik.isValid || formik.isSubmitting
            ? loginStyles.login__button_disabled
            : "hover"
        }`}
      >
        {formik.isSubmitting ? "שליחה" : "כניסה"}
      </button>
    </form>
  );
}

export default LoginEmailForm;
