import React, { useRef, useState } from "react";
import videoStyles from "./video.module.css";

const Video: React.FC<{ src: string }> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const togglePlay = async () => {
    if (videoRef.current) {
      if (isPlaying) {
        await videoRef.current.pause();
      } else {
        await videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <video ref={videoRef} className={videoStyles.video} crossOrigin="anonymous" controls>
        <source src={src} type="video/mp4"/>
          <source src={src} type="video/ogg"/>
            Your browser does not support the video tag.
          </video>
          <button
            className={`${videoStyles.playBtn} ${isPlaying && videoStyles.isPlaying
              }`}
            onClick={togglePlay}
          />
        </>
        );
};

        export default Video;
