import myDayStyles from "./myDay.module.css";
import venchy from "../../images/questionnaire_doctor.png";
import { getPrintDate } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { useAppSelector } from "../../services/hooks";
import {
  useGetCurrentQuestionnaireByUsersIdQuery,
  useGetMessageByUserIdQuery,
  useGetExercisesByUserIdQuery,
  useGetAppointmentsByUserIdQuery,
} from "../../services/api/user.api";
import { userSelector } from "../../services/selectors/user.selectors";
import { useState } from "react";
import Slider from "../slider/slider";

function MyDay() {
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const userName = user.firstName ? user.firstName : "אורח";
  const texts = [
    `שלום ${userName}, ברוכה הבאה לאפליקציה שלנו, אני כאן בשבילך לעזור לך 24 שעות. תרגישי בנוח לפנות אלי. אני אוכל לתת לך מענה טכני אם תצרכי במערכת עצמה. מעבר לזה אני אשתדל להיות איתך בקשר כל יום. אשאל בשלומך, אציע לך שירותים שיש לנו. וגם כשיהיו פיצ’רים חדשים אני אהיה מהראשונים להזכיר לך. מאחל לך הנאה צרופה באפליקציה :)`,
    `התייחסו לעצמכם באדיבות ובכבוד, והימנעו מביקורת עצמית. פנו זמן לתחביבים ולפרויקטים האהובים עליכם, או הרחיבו אופקים. ערכו תשבץ יומי, שתלו גינה, קחו שיעורי ריקוד, למדו לנגן על כלי נגינה או השתלטו בשפה אחרת.`,
    `הקף את עצמך באנשים טובים. אנשים עם קשרים משפחתיים או חברתיים חזקים הם בדרך כלל בריאים יותר מאלה שאין להם רשת תמיכה. תכנן תוכניות עם בני משפחה וחברים תומכים, או חפש פעילויות שבהן תוכל להכיר אנשים חדשים, כגון מועדון, חוג או קבוצת תמיכה.`,
    `למד כיצד להתמודד עם לחץ. תרצו או לא, מתח הוא חלק מהחיים. תרגל מיומנויות התמודדות טובות: נסה אסטרטגיות מתח של דקה אחת, עשה טאי צ'י, עשה פעילות גופנית, צא לטיול בטבע, שחק עם חיית המחמד שלך או נסה לכתוב יומן כמפחית מתח. כמו כן, זכרו לחייך ולראות את ההומור בחיים. מחקרים מראים שצחוק יכול לחזק את המערכת החיסונית שלך, להקל על הכאב, להרגיע את הגוף ולהפחית מתח.`,
  ];
  const updates = user.updates ? user.updates : texts;
  const exercises = user.exercises;
  const [activeUpdatesIndex, setActiveUpdatesIndex] = useState(0);
  const [activeAppointmentsIndex, setActiveAppointmentsIndex] = useState(0);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);

  useGetMessageByUserIdQuery({
    companyId: user.companyId,
    userId: Number(user.id),
  });
  useGetCurrentQuestionnaireByUsersIdQuery(
    {
      guid: user.guid,
      userId: Number(user.id),
    },
    { refetchOnMountOrArgChange: true }
  );
  useGetExercisesByUserIdQuery({ companyId: user.companyId, guid: user.guid });
  useGetAppointmentsByUserIdQuery({
    companyId: user.companyId,
    guid: user.guid,
  });

  return (
    <div className={myDayStyles.myDay}>
      <h3 className={myDayStyles.myDay__title}>כמה דברים במיוחד בשבילך:</h3>
      <div className={myDayStyles.myDay__section}>
        <h4 className={myDayStyles.myDay__sectionTitle}>עדכונים</h4>
        <div className={myDayStyles.notifications}>
          <div className={myDayStyles.notifications__venchy}>
            <img
              src={venchy}
              className={myDayStyles.notifications__img}
              alt={"תמונה של וונצ’י"}
            />
            <div>
              <p className={myDayStyles.notifications__name}>וונצ’י</p>
              <p className={myDayStyles.notifications__date}>
                {getPrintDate(new Date())}
              </p>
            </div>
          </div>
          <Slider
            items={updates}
            className={myDayStyles.notifications__text}
            activeIndex={activeUpdatesIndex}
            setActiveIndex={setActiveUpdatesIndex}
          />
          <button
            className={myDayStyles.nextBtn}
            onClick={() =>
              setActiveUpdatesIndex((prevIndex) =>
                prevIndex === updates.length - 1 ? 0 : prevIndex + 1
              )
            }
          />
        </div>
      </div>
      <Pagination
        items={updates}
        onClick={(index) => setActiveUpdatesIndex(index)}
        activeIndex={activeUpdatesIndex}
      />
      <div className={myDayStyles.myDay__section}>
        <h4 className={myDayStyles.myDay__sectionTitle}>תזכורות</h4>
        <ul className={myDayStyles.myDay__swiper}>
          {user.appointments && (
            <Slider
              items={user.appointments}
              className={myDayStyles.notifications__text}
              activeIndex={activeAppointmentsIndex}
              setActiveIndex={setActiveAppointmentsIndex}
            />
          )}

          {(!user.appointments || user.appointments.length == 0) && (
            <li className={myDayStyles.myDay__sectionCard}>
              <div>
                <p className={myDayStyles.myDay__sectionText}>
                  שלום {userName}, אני מזכיר לך להכנס ליומן שלך ולכתוב איך עובר
                  עלייך היום. למעבר ליומן
                  <span
                    className={myDayStyles.myDay__link}
                    onClick={() => navigate("/prevencher")}
                  >
                    לחצי כאן
                  </span>
                </p>
              </div>
            </li>
          )}
        </ul>
        <button
          className={myDayStyles.nextBtn}
          onClick={() =>
            setActiveAppointmentsIndex((prevIndex) =>
              !user.appointments || prevIndex === user.appointments.length - 1
                ? 0
                : prevIndex + 1
            )
          }
        />
      </div>
      <div
        className={`${myDayStyles.myDay__section} ${myDayStyles.myDay__section_practice}`}
      >
        <h4 className={myDayStyles.myDay__sectionTitle}>תרגולים</h4>
        <Slider
          items={exercises}
          className={myDayStyles.notifications__text}
          activeIndex={activeVideoIndex}
          setActiveIndex={setActiveVideoIndex}
        />
        <button
          className={myDayStyles.nextBtn}
          onClick={() =>
            setActiveVideoIndex((prevIndex) =>
              prevIndex === exercises.length - 1 ? 0 : prevIndex + 1
            )
          }
          disabled={exercises?.length <= 2}
        />
      </div>
      <Pagination
        items={exercises.map((v) => v.title)}
        onClick={(index) => setActiveVideoIndex(index)}
        activeIndex={activeVideoIndex}
      />
    </div>
  );
}

export default MyDay;
