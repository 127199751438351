import mainContentLayoutStyles from "../../components/mainContentLayout/mainContentLayout.module.css";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { mobileWidth } from "../../utils/constants";
import Sidebar from "../../components/sidebar/sidebar";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Chat from "../../components/chat/chat";
import { Category, IUserChatData } from "../../types/types";
import Notifications from "../../components/notifications/notifications";
import { menuTitles } from "../../utils/categories";
import { useAppSelector } from "../../services/hooks";
import {
  chatConnectionSelector,
  chatUsersSelector,
} from "../../services/selectors/chat.selector";

interface IChatPageProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function ChatPage({ activeCategory, changeCategory }: IChatPageProps) {
  const [therapist, setTherapist] = useState<IUserChatData | undefined>(
    undefined
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const mobile = useMediaQuery(mobileWidth);
  const chatUsers = useAppSelector(chatUsersSelector);
  const connection = useAppSelector(chatConnectionSelector);

  console.log(chatUsers);

  useEffect(() => {
    if (connection && chatUsers.length > 0) {
      connection
        .invoke(
          "FetchChatDataForUsers",
          chatUsers.map((u) => u.userId)
        )
        .catch((err) =>
          console.error("Error calling FetchChatDataForUsers:", err)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    if (!therapist?.userId) {
      setTherapist(
        chatUsers.find((u) => u.appointment && !u.appointment.endTime)
      );
    } else {
      setTherapist((prev) => chatUsers.find((u) => u.userId === prev?.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUsers]);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mainContentLayoutStyles.main_chat
      } ${!openMenu && mainContentLayoutStyles.main_chat_small}`}
    >
      <Notifications showNotifications={showNotifications} />
      <div
        className={`${mainContentLayoutStyles.overlay} ${
          mobile && openMenu ? mainContentLayoutStyles.overlay_visible : ""
        }`}
      />
      <section
        className={`${mainContentLayoutStyles.mainContent} ${mainContentLayoutStyles.mainContent_chat}`}
        onClick={hideNotifications}
      >
        <div
          className={`${mainContentLayoutStyles.header} ${mainContentLayoutStyles.header_chat}`}
        >
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {menuTitles[activeCategory]}
          </h1>
          <button
            className={`${mainContentLayoutStyles.mainContent__bell} ${
              showNotifications &&
              mainContentLayoutStyles.mainContent__bell_invisible
            }`}
            onClick={() => {
              setShowNotifications(!showNotifications);
            }}
          />
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        <Chat
          setTherapist={setTherapist}
          therapist={therapist}
          openMenu={openMenu}
        />
      </section>
      {!mobile && (
        <ChatSideBar
          setTherapist={setTherapist}
          therapist={therapist}
          openMenu={openMenu}
        />
      )}
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={activeCategory}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
    </main>
  );
}

export default ChatPage;
