import questionnaireStyles from "./questionnaire.module.css";
import { useInView } from "react-intersection-observer";
import questionnaireDoctor from "../../images/questionnaire_doctor.png";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import {
  nextStepSelector,
  startQuestionSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import { replaceVariables } from "../../utils/utils";

function Questionnaire() {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const user = useAppSelector(userSelector);
  const nextStep = useAppSelector(nextStepSelector);
  const userName = user.firstName ? user.firstName : "אורח";

  const startQuestion = useAppSelector(startQuestionSelector).replace(/@name/g, userName);

  const getStartQuestion = () => {
    if (nextStep && nextStep[0].StartQuestion) {
      return nextStep[0].StartQuestion != "null"
        ? replaceVariables(nextStep[0].StartQuestion, user)
        : startQuestion;
    } else return startQuestion;
  };

  return (
    <>
      <Link
        ref={ref}
        to={"/prevencher"}
        state={{ pathname: "chat" }}
        className={questionnaireStyles.questionnaire}
      >
        <img
          src={questionnaireDoctor}
          alt="bpreven doctor"
          className={questionnaireStyles.questionnaire__doctor}
        />

        <p className={questionnaireStyles.questionnaire__answer}>
          {getStartQuestion()}
        </p>
      </Link>
      {!inView && (
        <Link
          to={"/prevencher"}
          state={{ pathname: "chat" }}
          className={`${questionnaireStyles.questionnaire_small}`}
        >
          <img
            src={questionnaireDoctor}
            alt="bpreven doctor"
            className={questionnaireStyles.questionnaire__doctor}
          />
          <p className={questionnaireStyles.questionnaire__answer_small}>
            אני תמיד כן בשבילך
          </p>
        </Link>
      )}
    </>
  );
}

export default Questionnaire;
