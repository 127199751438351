import prevencherChatStyles from "./prevencherChat.module.css";
import { venchyData } from "../../utils/constants";
import DOMPurify from "dompurify";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Message from "../message/message";
import { useAppSelector } from "../../services/hooks";
import {
  currentQuestionSelector,
  currentQuestionIdSelector,
  endQuestionSelector,
  nextQuestionIdSelector,
  prevencherChatMessagesSelector,
  questionnaireIdSelector,
  questionsOptionsSelector,
  userSelector,
  allAnswersSelector,
  currentQuestionnaireCompanyIdSelector,
} from "../../services/selectors/user.selectors";
import {
  useGetCurrentQuestionnaireByUsersIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useLazyGetNextQuestionByQuestionnaireIdQuery,
  useLazyGetQuestionByQuestionnaireIdQuery,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import { IQuestionOption } from "../../services/types/user.types";
import { useActions } from "../../services/hooks/useActions";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";
import {
  formatDateTimeInIsrael,
  israeliPhoneRegExp,
  replaceVariables,
} from "../../utils/utils";

function PrevencherChat() {
  const inputRef = useRef<HTMLInputElement>(null);
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const user = useAppSelector(userSelector);
  const allAnswers = useAppSelector(allAnswersSelector);
  const nextQuestionId = useAppSelector(nextQuestionIdSelector);
  const questionnaireId = useAppSelector(questionnaireIdSelector);
  const currentQuestionnaireCompanyId = useAppSelector(
    currentQuestionnaireCompanyIdSelector
  );
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const questionsOptions = useAppSelector(questionsOptionsSelector);
  const endQuestion = useAppSelector(endQuestionSelector);
  const messages = useAppSelector(prevencherChatMessagesSelector);

  const { setMessages, setAnswers, setNextQuestion } = useActions();

  const [isDataLoaded, setIsDataLoaded] = useState(questionnaireId >= 0);
  const [options, setOptions] = useState<IQuestionOption[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const [getQuestionById, getQuestionByIdResult] =
    useLazyGetQuestionByQuestionnaireIdQuery();
  const [getNextQuestionById] = useLazyGetNextQuestionByQuestionnaireIdQuery();
  const [insertAllAnswers] = useInsertAllAnswersQuestionsMutation();

  useGetCurrentQuestionnaireByUsersIdQuery(
    { guid: user.guid, userId: Number(user.id) },
    { skip: !(!questionnaireId || questionnaireId == -1) }
  );

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    //@ts-ignore
    if (nextQuestionId === null || nextQuestionId === "null") {
      setNextQuestion();
    }
  }, [nextQuestionId]);

  useEffect(() => {
    if (nextQuestionId >= 0 && questionnaireId >= 0) {
      setIsDataLoaded(true);
    }
  }, [nextQuestionId, questionnaireId]);

  useEffect(() => {
    if (questionnaireId >= 0)
      getQuestionById({
        currentQuestionnAirId: questionnaireId,
        nextQuestionId: nextQuestionId ? nextQuestionId : currentQuestionId,
        skip: !isDataLoaded,
      });
  }, [isDataLoaded]);

  useEffect(() => {
    const message = currentQuestion?.Desc
      ? replaceVariables(currentQuestion?.Desc, user)
      : replaceVariables(endQuestion, user);

    if (
      getQuestionByIdResult.status === "fulfilled" &&
      currentQuestion?.Desc &&
      messages.at(-1)?.message != message
    ) {
      setMessages({
        id: prevencherId,
        date: new Date(),
        message: message,
        unread: false,
        nextQuestionId: 0,
      });
    }
  }, [getQuestionByIdResult.status]);

  useEffect(() => {
    if (currentQuestion?.DataTypesId == 1) setOptions(questionsOptions);
  }, [questionsOptions]);

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      currentQuestion?.Id === 72 || currentQuestion?.Id === 19
        ? setShowTextArea(true)
        : setShowInput(true);
    }
    if (currentQuestion?.DataTypesId === 5) {
      setIsLoading(true);
      setOptions([]);
      delay(1500).then(() => {
        if (currentQuestion.NextQuestionId) {
          getNextQuestionById({
            currentQuestionId: currentQuestionId,
            nextQuestionId: currentQuestion.NextQuestionId,
            skip: false,
          });
        } else {
          setIsLoading(false);
          setIsEnd(true);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [currentQuestion, getNextQuestionById, setOptions]);

  useEffect(() => {
    if (isEnd) {
      insertAllAnswers({
        answers: allAnswers,
        companyId: 1,
        QuestionnaireCompanyId: currentQuestionnaireCompanyId ?? 0,
      });
      setIsEnd(false);
    }
  }, [isEnd]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const scrollToBottom = () => {
    setTimeout(
      () => lastElRef.current?.scrollIntoView({ behavior: "smooth" }),
      0
    );
  };

  const onOptionClick = (option: IQuestionOption) => {
    setIsLoading(true);
    const date = formatDateTimeInIsrael(new Date());
    setMessages({
      messageId: `o${option.Id}`,
      id: user.id,
      date: date,
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    setOptions([]);
    setAnswers({
      Id: "0",
      QuestionnaireId: questionnaireId.toString(),
      QuestionsId: currentQuestionId.toString(),
      UsersId: Number(user.id) || null,
      Answer: null,
      AnswerDate: date,
      QuestionsOptionsIds: option.Id.toString(),
      ...(user.id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
    });
    delay(1500).then(() => {
      if (option.NextQuestionId) {
        getNextQuestionById({
          currentQuestionId: currentQuestionId,
          nextQuestionId: option.NextQuestionId,
          skip: false,
        });
      } else {
        setIsEnd(true);
      }
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let sanitizedContent = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
      ALLOWED_ATTR: [], // Allow no attributes
    });
    currentQuestion?.Id === 24
      ? handleTelInputChange(e)
      : setInputValue(sanitizedContent);
  };
  const onInputSubmit = () => {
    if (!inputValue) {
      return;
    }
    if (currentQuestion?.Id === 24 && !israeliPhoneRegExp.test(inputValue)) {
      setMessages({
        messageId: `${inputValue}error`,
        id: prevencherId,
        date: formatDateTimeInIsrael(new Date()),
        unread: false,
        message: `${inputValue} - מספר נייד לא תקין`,
        nextQuestionId: currentQuestion?.NextQuestionId,
      });
      return;
    }
    setShowInput(false);
    setShowTextArea(false);
    setIsLoading(true);
    const date = formatDateTimeInIsrael(new Date());
    setMessages({
      messageId: `${currentQuestionId}${inputValue}`,
      id: user.id,
      date: date,
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      setAnswers({
        Id: "0",
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: Number(user.id) || "0",
        Answer: inputValue,
        AnswerDate: date,
        ...(user.id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
      });
      setIsLoading(false);
      setInputValue("");
      if (!currentQuestion?.NextQuestionId) {
        setIsEnd(true);
      }
    });
  };
  const handleTelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      onInputSubmit();
    }
  };

  const handleFocus = () => {
    inputRef?.current &&
      inputRef.current.scrollIntoView({ behavior: "smooth" });
  }; //for ios mobile
  return (
    <div className={`${prevencherChatStyles.section}`}>
      {messages.length > 0 &&
        messages.map((message, index) => (
          <Message
            key={index}
            isOwnMessage={message.id != prevencherId}
            therapist={venchyData}
            message={message}
          />
        ))}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            ref={inputRef}
            onFocus={handleFocus}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 23 ? 10 : 300}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
            onClick={() => {}}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {showTextArea && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <textarea
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {isLoading && (
        <div>
          <Loader />
          <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
        </div>
      )}
      {messages.length > 0 && options.length > 0 && (
        <ul className={prevencherChatStyles.variants}>
          {options.map((o, index) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={index}
              onClick={() => onOptionClick(o)}
            >
              <span>{replaceVariables(o.Desc, user)}</span>
            </li>
          ))}
        </ul>
      )}
      <div ref={lastElRef} />
    </div>
  );
}

export default PrevencherChat;
