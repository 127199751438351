import avatar2 from "../images/therapist2.png";
import { disorders } from "./disorders";
import avatar1 from "../images/therapist1.png";
import video from "../images/therapist_video.jpg";
import { ITherapist } from "../types/types";

export const therapist1 = {
  id: "1",
  name: "פרופסור ברוך הלברטל",
  status: "פסיכיאטר",
  avatar: avatar1,
  color: "#34A3FF",
  online: true,
  tag: "המטפל שלי",
  phone: "052-8393948",
  video: video,
  fields: disorders,
  about:
    "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
};
export const therapist2 = {
  id: "2",
  name: "דוקטור תמר נאמן",
  status: "פסיכולוגית",
  avatar: avatar2,
  color: "#2F42C2",
  online: false,
  phone: "052-8393948",
  video:
    "https://media.istockphoto.com/id/1285827557/video/woman-looks-at-webcam-records-new-videoblog-makes-a-motivational-speech.mp4?s=mp4-640x640-is&k=20&c=o4nQ6dApDvNJxzkDcTgy5irC3ikOwSGhLQOn3MPdlOg=",
  fields: disorders,
  about:
    "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
};
export const therapist3 = {
  id: "3",
  name: "דוקטור יובל בר",
  status: "פסיכולוגית",
  avatar:
    "https://media.istockphoto.com/id/1395128697/photo/psychologist-session.jpg?s=1024x1024&w=is&k=20&c=O3UeEbrI9F8YYg-l5vYZb6pb98sIMKTTqYL6m383Ono=",
  color: "#121759",
  online: false,
  phone: "050-763827642",
  video:
    "https://media.istockphoto.com/id/1285827557/video/woman-looks-at-webcam-records-new-videoblog-makes-a-motivational-speech.mp4?s=mp4-640x640-is&k=20&c=o4nQ6dApDvNJxzkDcTgy5irC3ikOwSGhLQOn3MPdlOg=",
  fields: disorders,
  about:
    "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
};
export const prescriptionsExample = [
  {
    id: "6532512",
    medicine: "Nocturno",
    doze: "3.75 mg",
    instructions: "לבליעה 1 טבליה 1 פעם ביום (בוקר 1 טבליה) לתקופה של 30 ימים",
    date: new Date("May 2, 2023 12:00:00"),
    therapist: therapist1,
    exp: new Date("July 2, 2023 12:00:00"),
  },
  {
    id: "6532598",
    medicine: "Xanax",
    doze: "250 mcg",
    instructions: "לבליעה 1 טבליה 1 פעם ביום (בוקר 1 טבליה) לתקופה של 30 ימים",
    date: new Date("May 2, 2023 12:00:00"),
    therapist: therapist1,
    exp: new Date("May 2, 2024 12:00:00"),
  },
];
// export const chatTherapists: Partial<ITherapist>[] = [
//   {
//     id: "1",
//     name: "פרופסור ברוך הלברטל",
//     status: "פסיכיאטר",
//     avatar: avatar1,
//     online: true,
//     phone: "052-8393948",
//     video: video,
//     tag: "המטפל שלי",
//     about:
//       "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
//     messages: [
//       {
//         messageId: "1",
//         id: "1",
//         date: new Date("December 17, 2022 03:24:00"),
//         message: "הי יעל, אני מבין, זה חלק מהעניין לצערי",
//         unread: false,
//       },
//       {
//         messageId: "2",
//         id: "123456",
//         date: new Date("December 17, 2022 03:25:00"),
//         message: "נכון, מה אתה מציע שאני אעשה עם זה?",
//         unread: false,
//       },
//       {
//         messageId: "3",
//         id: "1",
//         date: new Date(Date.now() - 86400000), //yesterday
//         message: "בוא נדבר מחר על זה בפגישה האישית שלנו, אוקי?",
//         unread: false,
//       },
//       {
//         messageId: "4",
//         id: "123456",
//         date: new Date(),
//         message: "כאן במקרה?",
//         unread: false,
//       },
//       {
//         messageId: "5",
//         id: "1",
//         date: new Date(),
//         message: "תמיד. מה שלומך?",
//         unread: true,
//       },
//     ],
//   },
//   {
//     id: "2",
//     name: "דוקטור תמר נאמן",
//     status: "פסיכולוגית",
//     avatar: avatar2,
//     online: false,
//     phone: "052-8393948",
//     video: video,
//     tag: "המטפל שלי",
//     about:
//       "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
//     messages: [
//       {
//         messageId: "1",
//         id: "2",
//         date: new Date("December 17, 2022 03:24:00"),
//         message: "הי יעל, אני מבין, זה חלק מהעניין לצערי",
//         unread: false,
//       },
//       {
//         messageId: "2",
//         id: "123456",
//         date: new Date("December 17, 2022 03:25:00"),
//         message: "נכון, מה אתה מציע שאני אעשה עם זה?",
//         unread: false,
//       },
//       {
//         messageId: "3",
//         id: "2",
//         date: new Date("December 17, 2022 07:24:00"),
//         message:
//           "סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת. הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק. ורגם בורק? לתיג ישבעס...?",
//         unread: false,
//       },
//       {
//         messageId: "4",
//         id: "123456",
//         date: new Date(),
//         message: "כאן במקרה?",
//         unread: true,
//       },
//       {
//         messageId: "5",
//         id: "2",
//         date: new Date(),
//         message: "תמיד. מה שלומך?",
//         unread: true,
//       },
//       {
//         messageId: "6",
//         id: "123456",
//         date: new Date(),
//         message:
//           "זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק. ורגם בורק? לתיג ישב כאן במקרה?",
//         unread: true,
//       },
//     ],
//   },
//   {
//     id: "3",
//     name: "דן בוגוליובוב",
//     status: "שירות לקוחות",
//     avatar: avatar1,
//     online: false,
//     phone: "052-8393911",
//     video: video,
//     about:
//       "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
//   },
//   {
//     id: "4",
//     name: "פרופסור נתנאל ברלין",
//     status: "פסיכולוג",
//     avatar: avatar1,
//     online: false,
//     phone: "052-8393911",
//     tag: "המטפל שלי",
//     video: video,
//     about:
//       "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה.  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
//   },
// ];
