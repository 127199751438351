import {
  IAppointmentExample,
  IInvoice,
  IPrescription,
  ISettings,
  ITherapist,
  IVideo,
} from "../types/types";
import {
  prescriptionsExample,
  therapist1,
  therapist2,
  therapist3,
} from "./therapists";
import { defaultSettings } from "./constants";

const invoicesExample: IInvoice[] = [
  { id: "1", date: new Date("May 2, 2023 12:00:00"), sum: "20₪" },
  { id: "2", date: new Date("June 2, 2023 12:00:00"), sum: "20₪" },
];
interface ITestUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  account: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  plan: string;
  card: string;
  unreadMessages: number;
  settings: ISettings;
  invoices: IInvoice[];
  avatar: string;
  prescriptions: IPrescription[];
  therapists: ITherapist[];
  appointments: IAppointmentExample[];
  videos: IVideo[];
}
export const testData: ITestUser = {
  id: "123456",
  firstName: "יעל",
  lastName: "מרדכי",
  fullName: "יעל מרדכי",
  account: "4584",
  email: "test@bpreven.com",
  password: "78ushgd00ghdd1gmu7km",
  phone: "0544832880",
  address: "",
  plan: "basic",
  card: "3456",
  unreadMessages: 3,
  settings: defaultSettings,
  prescriptions: prescriptionsExample,
  invoices: invoicesExample,
  avatar:
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
  therapists: [therapist1, therapist2, therapist3],
  appointments: [
    { id: 0, therapist: therapist1, date: new Date("June 20, 2023 12:00:00") },
    {
      id: 1,
      therapist: therapist2,
      date: new Date("June 20, 2023 13:15:00"),
    },
  ],
  videos: [],
};

export interface IUserAction {
  text: string;
  value: string;
  path: string;
}
export const userActions: IUserAction[] = [
  { text: "עידכון נתונים", value: "editUserData", path: "update" },
  { text: "פרתים אישיים", value: "editPersonalData", path: "personal" },
  { text: "מסמכים", value: "documents", path: "documents" },
  { text: "מרשמים", value: "prescriptions", path: "prescriptions" },
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  { text: "תרגילים", value: "exercises", path: "exercises" },
];

export const userActionsEdit: IUserAction[] = [
  { text: "פרתים אישיים", value: "editPersonalData", path: "personal" },
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  { text: "תרגילים", value: "exercises", path: "exercises" },
  { text: "תכנית טיפול", value: "treatmentPlan", path: "treatment_plan" },
  { text: "עדכונים", value: "updates", path: "updates" },
  { text: "דוח RRM", value: "rrm", path: "rrm" },
  { text: "מסמכים", value: "documents", path: "documents" },
  { text: "מרשמים", value: "prescriptions", path: "prescriptions" },
  {
    text: "דוחות פריוונצ'ר",
    value: "prevencherReports",
    path: "prevencher_reports",
  },
  { text: "דוחות טיפול", value: "treatmentReports", path: "treatment_reports" },
  {
    text: "דוחות שאלונים",
    value: "questionsReports",
    path: "questions_reports",
  },
  { text: "דוחות EMD", value: "emd", path: "emd" },
  { text: "דוחות GAR", value: "gar", path: "gar" },
];
