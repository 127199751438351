import popupStyles from "./cancelAppointmentConfirmation.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IAppointmentExample, IPopupWithoutProps } from "../../../types/types";
import { useLazyGetTherapistDatesQuery } from "../../../services/api/therapists.api";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Modal from "../modal/Modal";
import AppointmentSuccessPopup from "../appointment_success_popup/appointment_success_popup";
import { appointmentOptions } from "../../../utils/constants";
import { useCancelAppointmentMutation } from "../../../services/api/user.api";
import { useActions } from "../../../services/hooks/useActions";
import CloseButton from "../../closeButton/closeButton";

interface CancelAppointmentConfirmationPopupProps extends IPopupWithoutProps {
  appointmentData: IAppointmentExample;
}

function CancelAppointmentConfirmationPopup({
  onClose,
  appointmentData,
}: CancelAppointmentConfirmationPopupProps) {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const [fetchDates, { data, isError }] = useLazyGetTherapistDatesQuery();
  const [
    cancelAppointment,
    { data: cancelAppointmentData, isError: isCancelAppointmentError },
  ] = useCancelAppointmentMutation();
  const { cancelAppointment: cancelAppointmentLocal } = useActions();

  return openModal ? (
    <Modal
      onClose={() => {
        onClose();
        setOpenModal(false);
      }}
    >
      <AppointmentSuccessPopup
        type={appointmentOptions.Cancel}
        onClose={() => {
          onClose();
          setOpenModal(false);
        }}
      />
    </Modal>
  ) : (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>
        האם אתה בטוח שברצונך לבטל את הפגישה?
      </h2>
      <p className={popupStyles.popup__text}>ניתן לשנות את מועד הפגישה.</p>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        type="button"
        className={`button hover ${popupStyles.popup__btn} ${popupStyles.popup__btn_type_change}`}
        onClick={() => {
          fetchDates(appointmentData.therapist.id);
          navigate("search", {
            replace: true,
            state: { appointmentData },
          });
        }}
      >
        לשנות את מועד הפגישה
      </button>
      <button
        type="button"
        className={`button hover ${popupStyles.popup__btn}`}
        onClick={() => {
          cancelAppointment({ appointmentId: appointmentData.id });
          cancelAppointmentLocal({ id: appointmentData.id });
          setOpenModal(true);
        }}
      >
        לבטל את הפגישה
      </button>
    </article>
  );
}

export default CancelAppointmentConfirmationPopup;
