import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";
import { ICall } from "../../types/types";
import { MutableRefObject } from "react";
import { IAppointment, IChatMessage, IUserChatData } from "../../types/types";
import { userApi } from "../api/user.api";
import { filterAppointments } from "../../utils/utils";

interface ChatState {
  isConnected: boolean;
  connection?: HubConnection;
  chatUsers: IUserChatData[];
  call: ICall;
  callAccepted: boolean;
  callEnded: undefined | string;
  openModalCallEnded: boolean;
  myStream: MediaStream | undefined;
  userStream: MediaStream | undefined;
  offer: string | undefined;
  myVideo: MutableRefObject<HTMLVideoElement> | null;
  userVideo: MutableRefObject<HTMLVideoElement> | null;
  isMicMuted: boolean;
}
export const videoCallInitialState: ICall = {
  isReceivingCall: false,
  from: {
    name: "",
    avatar: "",
  },
  signal: { type: "offer" },
};
const initialState: ChatState = {
  isConnected: false,
  chatUsers: [],
  connection: undefined,
  call: videoCallInitialState,
  offer: undefined,
  callAccepted: false,
  callEnded: undefined,
  openModalCallEnded: false,
  myStream: undefined,
  userStream: undefined,
  myVideo: null,
  userVideo: null,
  isMicMuted: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWsConnection(state, action: PayloadAction<HubConnection>) {
      console.log(action);
      state.connection = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    addMessage(state, { payload }: { payload: IChatMessage }) {
      state.chatUsers = state.chatUsers.map((u) =>
        u.userId === payload.senderId || u.userId === payload.receiverId
          ? { ...u, messages: [...u.messages, payload] }
          : u
      );
    },
    endCall(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        call: videoCallInitialState,
        callEnded: payload.content.split("by:")[1],
      };
    },
    setUserConnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: true } : usr
        ),
      };
    },
    setUserTyping(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload.senderId
            ? {
                ...usr,
                isTyping: payload.content.split(":")[1] === "1",
              }
            : usr
        ),
      };
    },
    setUserDisconnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: false } : usr
        ),
      };
    },
    setCall(state, { payload }: { payload: Partial<ICall> }) {
      return {
        ...state,
        call: { ...state.call, ...payload },
      };
    },
    setCallAccepted(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        callAccepted: payload,
      };
    },
    setCallEnded(state, { payload }: { payload: string | undefined }) {
      return {
        ...state,
        callEnded: payload,
      };
    },
    setOpenModalCallEnded(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        openModalCallEnded: payload,
      };
    },
    setStartCall(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        startCall: payload,
      };
    },
    updateChatData(state, { payload }: { payload: Partial<IUserChatData>[] }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((u) => {
          const user = payload.find((us) => us.userId === u.userId);
          return {
            ...u,
            online: !!user?.online,
            messages: user?.messages || u.messages,
          };
        }),
      };
    },

    setMyStream(state, { payload }: { payload: MediaStream | undefined }) {
      console.log(payload);
      return {
        ...state,
        myStream: payload,
      };
    },
    setUserStream(state, { payload }: { payload: MediaStream | undefined }) {
      return {
        ...state,
        userStream: payload,
      };
    },
    setOffer(state, { payload }: { payload: string }) {
      return {
        ...state,
        offer: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getAppointmentsByUserId.matchFulfilled,
      (state, { payload }: { payload: IAppointment[] }) => {
        const filteredAppointments = filterAppointments(payload);
        console.log(filteredAppointments);
        state.chatUsers = filteredAppointments.map((a) => ({
          userId: a.appointments.therapistsId,
          fullName: a.therapistName,
          online: false,
          isTyping: false,
          messages: [],
          appointment: a.appointments,
        }));
      }
    );
  },
});
export const { actions } = chatSlice;
export const {
  setWsConnection,
  setConnected,
  addMessage,
  setUserTyping,
  updateChatData,
  endCall,
  setCall,
  setOffer,
} = chatSlice.actions;
export default chatSlice.reducer;
