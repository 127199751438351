import sideBarSmallStyles from "./sideBarSmall.module.css";
import venchySide from "../../images/venchy_prevencher.png";
import { useNavigate } from "react-router-dom";

function SideBarSmall() {
  const navigate = useNavigate();
  return (
    <div className={sideBarSmallStyles.sidebar}>
      <img
        src={venchySide}
        className={sideBarSmallStyles.sidebar__venchy}
        alt={"תמונה של וונצ’י"}
      />
      <ul className={sideBarSmallStyles.sidebar__list}>
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_phone}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-phone" } })
          }
        />
        <li
          onClick={() => navigate("/forum")}
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_message}`}
        />
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_video}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-video" } })
          }
        />
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_chat}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-chat" } })
          }
        />
      </ul>
    </div>
  );
}

export default SideBarSmall;
